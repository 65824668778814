@import '../../../../scss/theme-bootstrap';

.faq {
  position: relative;
  border-top: 1px solid $color--gray--lightest;
  &__headline {
    @include swap_direction(padding, 32px 25px 32px 0);
    font-size: 20px;
    line-height: 1.1;
    position: relative;
    cursor: pointer;
    outline: none;
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(padding, 30px 30px 30px 0);
    }
    &-toggle {
      background: url('#{$base-theme-path}images/icons/src/plus.svg') 0 0 no-repeat;
      background-size: contain;
      display: inline-block;
      float: $rdirection;
      height: 22px;
      width: 22px;
      transition: background 0.3s ease-in;
      #{$rdirection}: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      .faq[aria-expanded='true'] & {
        background: url('#{$base-theme-path}images/icons/src/minus.svg') 0 0 no-repeat;
        background-size: contain;
      }
    }
  }
  &__content {
    height: auto;
    transition: height 0.3s ease-in;
    font-size: 14px;
    &[aria-hidden='true'] {
      height: 0;
      overflow: hidden;
    }
    &[aria-hidden='false'] {
      padding-bottom: 32px;
      @include breakpoint($bp--xlarge-up) {
        padding-bottom: 30px;
      }
    }
    p {
      margin: 0;
    }
  }
}
